<template>
  <div>
    <vue-tags-input
      v-model="tag"
      :tags="tags"
      @tags-changed="(newTags) => (tags = newTags)"
    />
    <input type="hidden" name="tags[]" :value="tagNames" >
  </div>
</template>
<script setup lang="ts">
import VueTagsInput from "@sipec/vue3-tags-input";

const tags = defineModel<{ text: string; tiClasses: string[] }[]>();

const tag = ref("");

const tagNames = computed(() => {
  return tags.value!.map((tag) => {
    return tag.text;
  });
});
</script>
